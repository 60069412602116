import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Highlight from "~/components/highlight/highlight";
import Button from "~/components/button";
import OrderedList from "~/components/List/Ordered";
import UnorderedList from "~/components/List/Unordered";
import Icon from "~/content/Icon"

const Page = () => {
  return (
    <LessonLayout
      current_step={6}
      lesson={"Lesson 1"}
      color={"ms"}
      total_count={10}
      module_title="Separating the Good from the Bad"
      >
      <ContentWrapper>
        <H2 underline>Step 6</H2>
        <p>Work with your teammates to look at 3 different websites to see if they meet the criteria on the rubric. At this point, you do not need to read all the information on the websites. Instead, look for whether the features are present or not.</p>
        <p>Take these steps as you look at each website:</p>

        <OrderedList>
          <li>Locate 8 information icons <Icon /> next to website features.</li>
          <li>Click on the icon to read the information about the website feature.</li>
          <li>Decide how well the website handles each feature</li>

          <UnorderedList>
            <li>If the feature is present and done well, click the <i className="text-sm text-white fas fa-thumbs-up bg-lime-500 rounded-full px-4 py-2" />.</li>
            <li>If the feature is not present (or not done well), click the <i className="text-sm text-white fas fa-thumbs-down bg-red-500 rounded-full px-4 py-2" />.</li>
            <li>If you are not sure whether the website includes this feature—or you think this feature does not apply, click the <i className="text-sm text-white fas fa-thumbs-up bg-yellow-500 rounded-full px-2 py-4 -rotate-90 mx-2" />.</li>
          </UnorderedList>

          <li>Next, match each icon to one of the criteria on your rubric. Use the "Website feature and quality" column on your rubric to keep track of how well the website meets each criterion. You can record your answers using symbols.</li>

          <div className="my-4 flex border-gray-900 border">
            <div className="flex-col">
              <div className="font-bold border-gray-900 border-r border-b px-4 py-2">Rating of website feature</div>
              <div className="border-r border-b border-gray-900 p-4 text-center"><i className="text-sm text-white fas fa-thumbs-up bg-lime-500 rounded-full px-4 py-2" /></div>
              <div className="border-b border-r border-gray-900 p-4 text-center"><i className="text-sm text-white fas fa-thumbs-down bg-red-500 rounded-full px-4 py-2" /></div>
              <div className="border-r border-gray-900 p-4 text-center"><i className="text-sm text-white fas fa-thumbs-up bg-yellow-500 rounded-full px-2 py-4 -rotate-90" /></div>
            </div>
            <div className="flex-col">
              <div className="font-bold border-b border-gray-900 flex-col px-4 py-2">Rubric website feature and quality symbol</div>
              <div className="border-b border-gray-900 p-4 text-center"><i className="fas fa-arrow-up text-xl" /></div>
              <div className="border-b border-gray-900 p-4 text-center"><i className="fas fa-arrow-down text-xl" /></div>
              <div className="p-4 text-center"><i className="fas fa-horizontal-rule text-xl" /></div>
            </div>
          </div>

          <li>In the last column, write a brief explanation for why you think the website does or does not meet the criteria. In other words, what are the strengths and weaknesses of each website for the listed criteria?</li>
        </OrderedList>

        <Highlight>
          Leave the Overall score column blank for now. You will assign a score later.
        </Highlight>

        <p>Click the buttons below to go to the different websites.</p>

        <div className="flex flex-col lg:flex-row">
          <Button
            action="secondary"
            className="my-2 lg:m-2"
            color="purple"
            href="/article/safe-environment"
            icon="external"
            title="website 1 lesson 3"
            withIcon={true}
          >
            Website 1
          </Button>
          <Button
            action="secondary"
            className="my-2 lg:m-2"
            color="purple"
            href="/article/active-life"
            icon="external"
            title="website 2 lesson 3"
            withIcon={true}
          >
            Website 2
          </Button>
          <Button
            action="secondary"
            className="my-2 lg:m-2"
            color="purple"
            href="/article/lung-health"
            icon="external"
            title="website 3 lesson 3"
            withIcon={true}
          >
            Website 3
          </Button>
        </div>

        <p>When you are done evaluating your website, work with your teammates to compare the 3 sites. Decide what are the strongest and weakest parts of the different sites.</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;
